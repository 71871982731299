<template>
	<page-container :page="page" :locale="locale" class="index">
		<section class="navigation">
			<div class="row">
				<div class="columns column12">
					<div class="logo" />
					<social-media class="desktop" :socials="socials" />
				</div>
			</div>
		</section>

		<main-content :title="page.title || page.header" :content="page.content" />

		<newsletter-content :content="page.newsletterContent" />

		<social-media class="mobile" :socials="socials" />

		<footers />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();

const { socials } = useDefaults();

await fetchPage();

// const showLanguageMenu = ref(false);

// const langmenu = computed(() => {
// 	return (page.value && page.value.langmenu) ?? [];
// });
//
// const toggleLanguageMenu = () => {
// 	showLanguageMenu.value = !showLanguageMenu.value;
// };
</script>

<style lang="scss" scoped>
.images-row {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
	margin: 50px auto;

	.image {
		width: calc(50% - 20px);
		margin: 0;

		&:last-child {
			margin: 100px 0 0;
		}
	}
}

.navigation {
	padding: 30px 0;

	.column12 {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		width: 228px;
		height: 89px;
		background: url('~/assets/images/logo-conversational-booking-engine.png') no-repeat center center;
		background-size: 228px;
	}
}

@media (max-width: 480px) {
	.navigation {
		.column12 {
			flex-flow: column wrap;
			gap: 20px;
		}
	}
}
</style>
