<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<p>© 2024 Becurious & Tactful</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<style lang="scss" scoped>
.footers {
	text-align: center;

	p {
		font-size: 20px;
		color: #fff;
	}

	.border {
		width: 100%;
		height: 1px;
		background: #03bfc0;
	}
}
</style>
